<div class="flex items-center" [class.opacity-60]="isRecording">
  <div class="relative" t-id="avatar-member-list">
    <!-- Skeleton loader -->
    <div
      *ngIf="isLoading"
      role="status"
      class="relative rounded-full overflow-hidden"
      [style.width.px]="width"
      [style.height.px]="height"
    >
      <div class="w-full h-full">
        <div
          class="w-full h-full rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center animate-pulse"
        >
          <svg
            class="w-full h-full text-gray-300 dark:text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"
            />
          </svg>
        </div>
      </div>
      <span class="sr-only">Loading...</span>
    </div>

    <!-- Avatar Image -->
    <img
      *ngIf="!isLoading && avatarUrl"
      t-id="avatar-of-members"
      [src]="avatarUrl"
      [height]="height"
      [width]="width"
      class="relative rounded-full cursor-pointer"
      [class.border-2]="border || hasBorder"
      [class.border-primary-600]="border"
      [class.border-white]="hasBorder && !border"
      [class.dark:border-gray-900]="hasBorder && !border"
      (click)="openUserProfile()"
      loading="lazy"
    />

    <!-- Default Avatar -->
    <div
      *ngIf="!isLoading && !avatarUrl"
      class="flex items-center justify-center rounded-full cursor-pointer text-sm font-semibold"
      [class.bg-primary-500]="!isAssistant"
      [class.text-white]="!isAssistant"
      [class.dark:bg-primary-300]="!isAssistant"
      [class.dark:text-gray-900]="!isAssistant"
      [class.bg-[#74AA9C]]="isAssistant"
      [class.text-white]="isAssistant"
      [class.border-2]="border || hasBorder"
      [class.border-primary-600]="border"
      [class.border-white]="hasBorder && !border"
      [class.dark:border-gray-900]="hasBorder && !border"
      [style.width.px]="width"
      [style.height.px]="height"
      (click)="openUserProfile()"
    >
      {{ prefix }}
    </div>

    <!-- Status Badge -->
    <div
      *ngIf="!isLoading && showStatus && status"
      class="absolute bottom-0 right-0 rounded-full"
      [ngStyle]="{ background: status }"
      [style.width.px]="statusSize"
      [style.height.px]="statusSize"
    ></div>

    <!-- External Badge -->
    <div
      *ngIf="!isLoading && showStatus && !status"
      class="absolute bottom-0 right-0"
      [style.width.px]="statusSize * 1 + 3"
      [style.height.px]="statusSize * 1 + 3"
    >
      <app-svg name="link" [height]="statusSize" [width]="statusSize"></app-svg>
    </div>
  </div>

  <!-- Username -->
  <div *ngIf="showUserName && userName && !isDropdownItem" class="ml-1">
    {{ userName }}
  </div>

  <!-- Username Skeleton -->
  <div
    *ngIf="isLoading && showUserName"
    class="ml-2 h-4 w-20 bg-gray-200 rounded dark:bg-gray-700 animate-pulse"
  ></div>
</div>

<div *ngIf="showUserName && userName && isDropdownItem">{{ userName }}</div>
