import { Store, State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { UsersStateModel } from '../models/UsersState';
import { UsersService } from '../../../api/services/users.service';
import {
  GetUsersListByTenant,
  GetAttachedUsersList,
  UpdateUserAvatar,
  UpdateUser,
  UpdateUserStatus,
  UpdateUsersAfterChange,
  QueueGetBulkUsersInfo,
  GetBulkUsersInfo,
  ChangeUserRole,
  DeleteUserAccount,
  TransferUserPermission,
  UsersSetEmoji,
  UsersEmojiPicker,
  UsersNewAccount,
  GetUsersByCursor,
  UpdateUsersSearch,
  GetUsersListFromAllUserTenants,
  PromoteUserToAdmin,
  ActivateUsers,
  DeactivateUsers,
} from '../actions/users.action';
import { UpdateTheme, UserSetAvatarImageUploadLoading } from '../actions/users.action';
import { SpaceGetUsersList } from '../actions/spaces.action';
import { ProjectGetUsersList } from '../actions/projects.action';
import { LogoutProcess, UpdateAuthUser } from '../actions/auth.action';
import { ChatsGet } from '../actions/chats.action';
import { UsersDbDto } from '../../../api/models';
import { AuthState } from './auth.state';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

@State<UsersStateModel>({
  name: 'Users',
  defaults: {
    loading: true,
    usersOfAllTenantsOfCurrentUser: [],
    tenantUsers: [],
    tenantUsersById: {},
    attachedUsers: [],
    usersStatusesUpdates: {},
    usersInfo: {},
    usersEmojiIsOpen: false,
    emoji: '',
    usersForManagement: {},
  },
})
@Injectable()
export class UsersState {
  usersInfoQueue = [];
  usersInfoTimer;

  @Selector()
  static getUserNameById(state: UsersStateModel) {
    return (userId: string) => {
      return state.tenantUsersById[userId] ? state.tenantUsersById[userId].userName : null;
    };
  }
  @Selector()
  static getUserNameAndStateById(state: UsersStateModel) {
    return (userId: string) => {
      return state.tenantUsersById[userId]
        ? {
            userName: state.tenantUsersById[userId].userName,
            state: state.tenantUsersById[userId].state,
            state_end: state.tenantUsersById[userId].state_end,
            state_available: state.tenantUsersById[userId].state_available,
            state_icon: state.tenantUsersById[userId].state_icon,
          }
        : null;
    };
  }

  /**
   * get users info
   * @param  {UsersStateModel} state
   */
  @Selector()
  static getUsersInfo(state: UsersStateModel) {
    return state.usersInfo;
  }

  @Selector()
  static getUsersForManagementInfo(state: UsersStateModel) {
    return state.usersForManagement;
  }

  @Selector()
  static isLoading(state: UsersStateModel) {
    return state.loading;
  }

  /**
   * get users statuses updates received via socket
   * @param  {UsersStateModel} state
   */
  @Selector()
  static getUsersStatusesUpdates(state: UsersStateModel) {
    return state.usersStatusesUpdates;
  }

  /**
   * get tenant users list
   * @param  {UsersStateModel} state
   */
  @Selector()
  static getTenantUsers(state: UsersStateModel) {
    return state.tenantUsers;
  }

  /**
   * get users list of all tenants of current user
   * @param  {UsersStateModel} state
   */
  @Selector()
  static usersofAllTenantsOfCurrentUser(state: UsersStateModel) {
    return state.usersOfAllTenantsOfCurrentUser;
  }

  /**
   * get tenant users list
   * @param  {UsersStateModel} state
   */
  @Selector()
  static getAttachedUsers(state: UsersStateModel) {
    return state.attachedUsers;
  }

  @Selector()
  static getUser(state: UsersStateModel) {
    return (userId: string) => state.tenantUsersById[userId];
  }

  /**
   * get user avatar image upload loading
   * @param  {UsersStateModel} state
   */
  @Selector()
  static getUserAvatarImageUploadLoading(state: UsersStateModel) {
    return (userId: string) => state.tenantUsersById[userId][`avatarImageUploadLoading`];
  }

  @Selector()
  static getUserState(state: UsersStateModel) {
    return (userId: string) => {
      return {
        state: state.tenantUsersById[userId].state,
        state_end: state.tenantUsersById[userId].state_end,
        state_available: state.tenantUsersById[userId].state_available,
        state_icon: state.tenantUsersById[userId].state_icon,
      };
    };
  }

  constructor(
    private usersService: UsersService,
    private store: Store,
    private readonly toastr: ToastrService,
    private readonly translocoService: TranslocoService,
  ) {}

  /**
   * Get users list by tenant action handler
   * @param  {patchState}: StateContext<UsersStateModel>
   * @param action: GetUsersListByTenant
   */
  @Action(GetUsersListByTenant)
  get_users_list_by_tenant(
    { patchState }: StateContext<UsersStateModel>,
    action: GetUsersListByTenant,
  ) {
    return this.usersService.usersGetListByTenantName({ isActive: true }).pipe(
      tap(
        (result) => {
          const tenantUsersById = {};
          for (const user of result) {
            tenantUsersById[user._id as string] = user;
          }
          patchState({
            loading: false,
            tenantUsers: result,
            tenantUsersById,
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get all attached users
   * @param  {patchState}: StateContext<UsersStateModel>
   * @param action: GetAttachedUsersList
   */
  @Action(GetAttachedUsersList)
  get_attached_users_list(
    { patchState }: StateContext<UsersStateModel>,
    action: GetAttachedUsersList,
  ) {
    return this.usersService.usersGetAttachedUserList({}).pipe(
      tap(
        (result) => {
          patchState({
            attachedUsers: result,
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Update avatar of a user
   * @param  {patchState}: StateContext<UsersStateModel>
   * @param action: UpdateUserAvatar
   */
  @Action(UpdateUserAvatar)
  updateUserAvatar(
    { getState, patchState }: StateContext<UsersStateModel>,
    action: UpdateUserAvatar,
  ) {
    const tenantUsersById = this.deepClone(getState().tenantUsersById);
    const tenantUsers = this.deepClone(getState().tenantUsers);
    const usersInfo = this.deepClone(getState().usersInfo);

    tenantUsersById[action.payload.userId].avatarUrl = action.payload.avatarUrl;

    for (const user of tenantUsers) {
      if (user._id === action.payload.userId) {
        user.avatarUrl = action.payload.avatarUrl;
      }
    }

    if (usersInfo[action.payload.userId]) {
      usersInfo[action.payload.userId].avatarUrl = action.payload.avatarUrl;
    }

    return patchState({ tenantUsersById, tenantUsers, usersInfo });
  }

  /**
   * Update avatar image upload loading of a user
   * @param  {patchState}: StateContext<UsersStateModel>
   * @param action
   */
  @Action(UserSetAvatarImageUploadLoading)
  setUserAvatarImageUploadLoading(
    { getState, patchState }: StateContext<UsersStateModel>,
    action: UserSetAvatarImageUploadLoading,
  ) {
    const tenantUsersById = this.deepClone(getState().tenantUsersById);
    tenantUsersById[action.payload.userId].avatarImageUploadLoading = action.payload.status;
    const tenantUsers = this.deepClone(getState().tenantUsers);
    for (const user of tenantUsers) {
      if (user._id === action.payload.userId) {
        user.avatarImageUploadLoading = action.payload.status;
      }
    }
    return patchState({ tenantUsersById, tenantUsers });
  }

  /**
   * Update a user
   * @param  {patchState}: StateContext<UsersStateModel>
   * @param action: UpdateUser
   */
  @Action(UpdateUser)
  updateUser({ getState, patchState }: StateContext<UsersStateModel>, action: UpdateUser) {
    const userDto = this.deepClone(getState().tenantUsersById)[action.payload.userId];
    for (const key of Object.keys(action.payload.body)) {
      userDto[key] = action.payload.body[key];
    }

    return this.usersService.usersUpdate({ body: userDto }).pipe(
      tap((result) => {
        const tenantUsersById: Record<string, UsersDbDto> = this.deepClone(
          getState().tenantUsersById,
        );
        tenantUsersById[action.payload.userId].name = result['name'];
        tenantUsersById[action.payload.userId].userName = result['userName'];
        tenantUsersById[action.payload.userId].email = result['email'];
        tenantUsersById[action.payload.userId].timezone = result['timezone'];
        tenantUsersById[action.payload.userId].uiTheme = result['uiTheme'];
        tenantUsersById[action.payload.userId].job = result['job'];
        tenantUsersById[action.payload.userId].department = result['department'];
        tenantUsersById[action.payload.userId].state = result['state'];
        tenantUsersById[action.payload.userId].state_end = result['state_end'];
        tenantUsersById[action.payload.userId].state_available = result['state_available'];
        tenantUsersById[action.payload.userId].state_icon = result['state_icon'];

        const tenantUsers: any[] = this.deepClone(getState().tenantUsers);
        for (const user of tenantUsers) {
          if (user._id === action.payload.userId) {
            user.name = result['name'];
            user.isActive = result['isActive'];
            user.userName = result['userName'];
            user.email = result['email'];
            user.uiTheme = result['uiTheme'];
          }
        }

        const usersInfo = this.deepClone(getState().usersInfo);
        usersInfo[action.payload.userId].userName = userDto.userName;
        usersInfo[action.payload.userId].isActive = userDto.isActive;
        if (userDto.status) {
          usersInfo[action.payload.userId].status = userDto.status;
        }

        const usersForManagement = this.deepClone(getState().usersForManagement) ?? {};
        const userForManagement = usersForManagement[action.payload.userId];

        if (userForManagement) {
          userForManagement.isActive = !!userDto.isActive;
        }

        patchState({ tenantUsersById, tenantUsers, usersInfo, usersForManagement });

        this.store.dispatch(new UpdateAuthUser(userDto));
      }),
    );
  }

  @Action(ActivateUsers)
  activateUser({ getState, patchState }: StateContext<UsersStateModel>, action: ActivateUsers) {
    return this.usersService
      .usersActivate({
        body: action.payload,
      })
      .pipe(
        tap((result) => {
          const actualState = getState();

          const usersforManagement = this.deepClone(actualState.usersForManagement);

          for (const updatedUser of action.payload.userIds) {
            if (usersforManagement[updatedUser] !== undefined) {
              usersforManagement[updatedUser].isActive = true;
            }
          }

          const usersInfo = this.deepClone(actualState.usersInfo);

          for (const updatedUser of action.payload.userIds) {
            if (usersInfo[updatedUser] !== undefined) {
              usersInfo[updatedUser].isActive = true;
            }
          }

          const tenantUsers = this.deepClone(actualState.tenantUsers);

          for (const updatedUser of action.payload.userIds) {
            if (tenantUsers[updatedUser] !== undefined) {
              tenantUsers[updatedUser].isActive = true;
            }
          }

          patchState({
            usersForManagement: {
              ...usersforManagement,
            },
            tenantUsers: [...tenantUsers],
            usersInfo: {
              ...usersInfo,
            },
          });
        }),
      );
  }

  @Action(DeactivateUsers)
  deactivateUser({ getState, patchState }: StateContext<UsersStateModel>, action: DeactivateUsers) {
    return this.usersService
      .usersDeactivate({
        body: action.payload,
      })
      .pipe(
        tap((result) => {
          const actualState = getState();

          const usersforManagement = this.deepClone(actualState.usersForManagement);

          for (const updatedUser of action.payload.usersToDeactivate) {
            if (usersforManagement[updatedUser] !== undefined) {
              usersforManagement[updatedUser].isActive = false;
              usersforManagement[updatedUser].roles = [];
            }
          }

          const usersInfo = this.deepClone(actualState.usersInfo);

          for (const updatedUser of action.payload.usersToDeactivate) {
            if (usersInfo[updatedUser] !== undefined) {
              usersInfo[updatedUser].isActive = false;
              usersInfo[updatedUser].roles = [];
            }
          }

          const tenantUsers = this.deepClone(actualState.tenantUsers);

          for (const updatedUser of action.payload.usersToDeactivate) {
            if (tenantUsers[updatedUser] !== undefined) {
              tenantUsers[updatedUser].isActive = false;
              tenantUsers[updatedUser].roles = [];
            }
          }

          patchState({
            usersForManagement: {
              ...usersforManagement,
            },
            tenantUsers: [...tenantUsers],
            usersInfo: {
              ...usersInfo,
            },
          });
        }),
      );
  }

  /**
   * Update some user after change
   * @param  {patchState}: StateContext<UsersStateModel>
   * @param action: UpdateUsersAfterChange
   */
  @Action(UpdateUsersAfterChange)
  updateUsers(
    { getState, patchState }: StateContext<UsersStateModel>,
    action: UpdateUsersAfterChange,
  ) {
    const tenantUsers: any[] = this.deepClone(getState().tenantUsers);
    for (const user of tenantUsers) {
      if (user._id === action.payload._id) {
        user.userName = action.payload.userName;
        user.name = action.payload.name;
        user.email = action.payload.email;
      }
    }

    const userDto = this.deepClone(getState().tenantUsersById)[action.payload._id];

    for (const key of Object.keys(action.payload)) {
      userDto[key] = action.payload[key];
    }

    const usersInfo = this.deepClone(getState().usersInfo);
    const tenantUsersById = this.deepClone(getState().tenantUsersById);

    patchState({
      tenantUsersById: {
        ...tenantUsersById,
        [action.payload._id]: {
          ...(tenantUsersById[action.payload._id] ?? {}),
          userName: action.payload.userName,
          name: action.payload.name,
          email: action.payload.email,
          state: action.payload.state,
          state_end: action.payload.state_end,
          status: action.payload.status,
          state_available: action.payload.state_available,
          state_icon: action.payload.state_icon,
        },
      },
      tenantUsers,
      usersInfo: {
        ...usersInfo,
        [action.payload._id]: {
          ...(usersInfo[action.payload._id] ?? {}),
          userName: userDto.userName,
          name: userDto.name,
          email: userDto.email,
          state: userDto.state,
          state_end: userDto.state_end,
          status: userDto.status,
          state_available: userDto.state_available,
          state_icon: userDto.state_icon,
        },
      },
    });
  }

  /**
   * Update a user theme
   * @param  {getState}: StateContext<UsersStateModel>
   * @param action: UpdateTheme
   */
  @Action(UpdateTheme)
  updateTheme({ getState }: StateContext<UsersStateModel>, action: UpdateTheme) {
    const user = this.deepClone(getState().tenantUsersById[action.payload.userId]);
    user.uiTheme = action.payload.uiTheme;
    this.store.dispatch(new UpdateUser({ userId: action.payload.userId, body: user }));
  }

  /**
   * Delete user account
   * @param  {getState}: StateContext<UsersStateModel>
   * @param action: UpdateTheme
   */
  @Action(DeleteUserAccount)
  delete_user({ getState, dispatch }: StateContext<UsersStateModel>, action: DeleteUserAccount) {
    const { userId, passwordData } = action.payload;
    return this.usersService
      .usersDeleteAccount({ id: userId, body: { password: passwordData } })
      .pipe(
        tap(
          (result) => {
            dispatch(new LogoutProcess(result));
            dispatch(ChatsGet);
          },
          (err) => {
            throw err.error;
          },
        ),
      );
  }

  /**
   * Delete user account
   * @param  {getState}: StateContext<UsersStateModel>
   * @param action: UpdateTheme
   */
  @Action(TransferUserPermission)
  transfer_user(
    { getState, dispatch }: StateContext<UsersStateModel>,
    action: TransferUserPermission,
  ) {
    const { idExtend, passwordData } = action.payload;
    return this.usersService
      .transferOwnership({ id: idExtend, body: { password: passwordData } })
      .pipe(
        tap(
          (result) => {
            dispatch(new DeleteUserAccount(action.payload));
          },
          (err) => {
            throw err.error;
          },
        ),
      );
  }

  /**
   * New user account
   * @param  {getState}: StateContext<UsersStateModel>
   * @param action: UsersNewAccount
   */
  @Action(UsersNewAccount)
  newAccount({ patchState, getState }: StateContext<UsersStateModel>, action: UsersNewAccount) {
    const {
      userName,
      userId,
      avatarUrl,
      status,
      createdAt,
      email,
      isActive,
      isDeleted,
      timezone,
      tenantName,
    } = action.payload;

    const state = getState();
    const user: UsersDbDto = {
      _id: userId,
      userName,
      avatarUrl,
      status,
      created_at: createdAt,
      email,
      isActive,
      isDeleted,
      timezone,
      tenantName,
    };

    const userInfo: {
      avatarUrl: string;
      status: string;
      userName?: string;
      isAssistant?: boolean;
      job?: string;
      department?: string;
      state?: string;
      isActive: boolean;
      state_end?: string;
      state_available?: string;
      state_icon?: string;
    } = {
      avatarUrl,
      status,
      userName,
      isActive,
    };

    patchState({
      tenantUsers: [...state.tenantUsers, user],
      tenantUsersById: { ...state.tenantUsersById, [userId]: user },
      usersInfo: { ...state.usersInfo, [userId]: userInfo },
    });
  }

  /**
   * Update users status user
   * @param  { getState, patchState }: StateContext<UsersStateModel>
   * @param action
   */
  @Action(UpdateUserStatus)
  updateUserStatus(
    { getState, patchState }: StateContext<UsersStateModel>,
    action: UpdateUserStatus,
  ) {
    const { userId, status } = action.payload;
    const { usersStatusesUpdates } = getState();
    const { usersInfo } = getState();
    const user = { ...usersInfo[userId] };

    user.state = null;
    user.state_end = null;
    user.state_icon = null;
    patchState({
      usersInfo: { ...usersInfo, [userId]: user },
      usersStatusesUpdates: { ...usersStatusesUpdates, [userId]: status },
    });
  }

  private deepClone(object) {
    return JSON.parse(JSON.stringify(object));
  }

  /**
   * Get bulk users info queue
   * @param  { getState, patchState }: StateContext<UsersStateModel>
   * @param action: QueueGetBulkUsersInfo
   */
  @Action(QueueGetBulkUsersInfo)
  queueGetBulkUsersInfo(
    { getState, patchState }: StateContext<UsersStateModel>,
    action: QueueGetBulkUsersInfo,
  ) {
    const userId = action.payload;
    const { usersInfo } = getState();
    if (!!userId && !this.usersInfoQueue.includes(userId) && !usersInfo[userId]) {
      this.usersInfoQueue.push(userId);
      if (this.usersInfoTimer) {
        clearTimeout(this.usersInfoTimer);
      }
      this.usersInfoTimer = setTimeout(() => {
        this.usersInfoTimer = null;
        this.store.dispatch(new GetBulkUsersInfo({}));
      }, 200);
    }
  }

  @Action(GetUsersListFromAllUserTenants)
  getUsersListFromAllUserTenants(
    { patchState }: StateContext<UsersStateModel>,
    action: GetUsersListFromAllUserTenants,
  ) {
    return this.usersService.usersGetListFromAllUserTenants().pipe(
      tap(
        (result) => {
          patchState({
            usersOfAllTenantsOfCurrentUser: result,
          });
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Get bulk users info queue
   * @param  { getState, patchState }: StateContext<UsersStateModel>
   * @param action: GetBulkUsersInfo
   */
  @Action(GetBulkUsersInfo)
  getBulkUsersInfo(
    { getState, patchState }: StateContext<UsersStateModel>,
    action: GetBulkUsersInfo,
  ) {
    if (this.usersInfoQueue.length <= 0) {
      return;
    }

    const { usersInfo } = getState();
    const userIds = this.usersInfoQueue.filter((item) => !!item).join(',');

    if (!userIds) {
      return;
    }

    return this.usersService
      .usersGetUsersInfo(this.usersInfoQueue.length === 0 ? {} : { userIds })
      .pipe(
        tap(
          (result) => {
            // const usersToAdd = action.payload.userId
            //   ? result.filter((user) => user._id === action.payload.userId)
            //   : result;
            this.usersInfoQueue = [];
            const resultObj = {};
            for (const item of result) {
              resultObj[item._id as string] = {
                avatarUrl: item.avatarUrl,
                status: item.status,
                userName: item.userName,
                isAssistant: item.isAssistant,
                job: item.job,
                department: item.department,
                state: item.state,
                state_end: item.state_end,
                state_available: item.state_available,
                state_icon: item.state_icon,
              };
            }
            patchState({
              usersInfo: { ...usersInfo, ...resultObj },
            });
          },
          (err) => {
            throw err.error;
          },
        ),
      );
  }

  @Action(UpdateUsersSearch)
  updateUsersSearch(
    { patchState, dispatch }: StateContext<UsersStateModel>,
    action: UpdateUsersSearch,
  ) {
    patchState({
      usersSearch: action.payload.search,
      usersForManagementCursor: undefined,
    });

    dispatch(new GetUsersByCursor());
  }

  /**
   * Get users paginated by cursor
   * @param  { getState, patchState }: StateContext<UsersStateModel>
   * @param action: GetUsersByCursor
   */
  @Action(GetUsersByCursor)
  getUsersByCursor(
    { getState, patchState }: StateContext<UsersStateModel>,
    action: GetUsersByCursor,
  ) {
    const currentState = getState();
    const limit = 17;

    const tenantName = this.store.selectSnapshot(AuthState.getUser)?.tenantName;

    return this.usersService
      .getUsersByCursor({
        cursor: currentState.usersForManagementCursor,
        tenantName,
        limit,
        search: currentState.usersSearch,
      })
      .pipe(
        tap(
          (result) => {
            if (!result.length) {
              return;
            }

            const resultObj = {};
            for (const item of result) {
              resultObj[item._id as string] = {
                avatarUrl: item.avatarUrl,
                status: item.status,
                email: item.email,
                location: item.timezone,
                registeredAt: item.created_at,
                userName: item.userName,
                isActive: item.isActive,
                isAssistant: item.isAssistant,
                job: item.job,
                department: item.department,
                state: item.state,
                state_end: item.state_end,
                state_available: item.state_available,
                state_icon: item.state_icon,
                roles: item.roles,
              };
            }

            const lastCursor = result[result.length - 1]._id.toString();

            patchState({
              usersForManagement: currentState.usersForManagementCursor
                ? { ...currentState.usersForManagement, ...resultObj }
                : { ...resultObj },
              usersForManagementCursor: lastCursor,
            });
          },
          (err) => {
            throw err.error;
          },
        ),
      );
  }

  /**
   * Change user role
   * @param  { getState, patchState }: StateContext<UsersStateModel>
   * @param action: ChangeUserRole
   */
  @Action(ChangeUserRole)
  changeUserRole({ getState, patchState }: StateContext<UsersStateModel>, action: ChangeUserRole) {
    return this.usersService.changeUserRole({ body: action.payload }).pipe(
      tap(
        (result) => {
          if (result.success) {
            if (action.payload.object === 'spaces') {
              this.store.dispatch(
                new SpaceGetUsersList({
                  id: action.payload.objectId,
                  exists: true,
                }),
              );
            } else if (action.payload.object === 'projects') {
              this.store.dispatch(
                new ProjectGetUsersList({
                  id: action.payload.objectId,
                  exists: true,
                }),
              );
            } else if (action.payload.object === 'tenants') {
              this.store.dispatch(new GetUsersListByTenant());
            }
          }
        },
        (err) => {
          throw err.error;
        },
      ),
    );
  }

  /**
   * Promote user to admin
   * @param  { getState, patchState }: StateContext<UsersStateModel>
   * @param action: PromoteUserToAdmin
   */
  @Action(PromoteUserToAdmin)
  promoteUserToAdmin(
    { getState, patchState }: StateContext<UsersStateModel>,
    action: PromoteUserToAdmin,
  ) {
    return this.usersService.promoteUserToAdmin({ body: action.payload }).pipe(
      tap(
        (result) => {
          if (result.body.success) {
            const currentState = getState();

            const modifiedUser = currentState.usersForManagement[action.payload.userId];
            // TODO
            patchState({
              usersForManagement: {
                ...currentState.usersForManagement,
                [action.payload.userId]: {
                  ...modifiedUser,
                  roles: modifiedUser.roles.some((role) => role.object === 'tenants')
                    ? (modifiedUser.roles ?? []).map((role) =>
                        role.object === 'tenants'
                          ? {
                              ...role,
                              roleName: 'Admin',
                            }
                          : role,
                      )
                    : [
                        ...modifiedUser.roles,
                        {
                          object: 'tenants',
                          roleName: 'Admin',
                        },
                      ],
                },
              },
            });

            this.toastr.success(
              this.translocoService.translate('settings.users.successfully-promoted-to-admin'),
            );
          }
        },
        (err) => {
          this.toastr.error(
            this.translocoService.translate('settings.users.could-not-promote-to-admin'),
          );
          throw err.error;
        },
      ),
    );
  }

  /**
   * Set emoji state - open/closed
   * @param  {patchState}: StateContext<ProjectsStateModel>
   * @param  {UsersEmojiPicker} action
   */
  @Action(UsersEmojiPicker)
  projects_emoji_picker({ patchState }: StateContext<UsersStateModel>, action: UsersEmojiPicker) {
    patchState({
      usersEmojiIsOpen: action.payload.usersEmojiPickerIsOpen,
    });
  }

  /**
   * Set emoji state - open/closed
   * @param  {patchState}: StateContext<ProjectsStateModel>
   * @param  {ProjectsSetEmoji} action
   */
  @Action(UsersSetEmoji)
  projects_set_emoji({ patchState }: StateContext<UsersStateModel>, action: UsersSetEmoji) {
    patchState({
      emoji: action.payload.selectedEmoji,
    });
  }
}
