import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription, combineLatest, distinctUntilChanged, debounceTime, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import memoizee from 'memoizee';

import { UserViewComponent } from '../../../modals/user-view/user-view.component';
import { QueueGetBulkUsersInfo } from '../../../shared/store/actions/users.action';
import { UsersState } from '../../../shared/store/states/users.state';
import { AuthState } from '../../../shared/store/states/auth.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SvgComponent } from '../../../shared/svgs/svg/svg.component';
import { ImageCacheService } from '../../../services/image-cache.service';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AngularSvgIconModule, SvgComponent],
})
export class AvatarComponent implements OnChanges, OnDestroy {
  private readonly logPrefix = '[AvatarComponent]';

  @Input() set userId(value: string) {
    console.log(`${this.logPrefix} Setting userId:`, { previous: this._userId, new: value });
    if (this._userId !== value) {
      this._userId = value;
      this.initAvatar();
    }
  }
  get userId(): string {
    return this._userId;
  }
  private _userId: string;
  @Input() width = 30;
  @Input() height = 30;
  @Input() defaultAvatar = 'assets/img/portrait/small/default-avatar.png';
  @Input() showStatus = true;
  @Input() notShowProfile = false;
  @Input() showUserName = false;
  @Input() statusSize = 10;
  @Input() border;
  @Input() hasBorder = false;
  @Input() isBreadcrumb: boolean;
  @Input() isDropdownItem: boolean;
  @Input() isRecording = false;
  @Input() isExternal = false;

  avatarUrl: string;
  status: string = null;
  userName: string;
  prefix: string;
  isAssistant = false;
  isLoading = true;

  usersStatusesUpdates$: Subscription;
  usersInfo$: Subscription;

  private static userIdsToFetch = new Set<string>();
  private static batchTimeout: any;

  private readonly destroy$ = new Subject<void>();

  private readonly memoizedUpdateAvatarInfo;
  private readonly memoizedSetInternalUserInfo;
  private readonly memoizedUpdateAssistantStatus;

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    protected modalService: NgbModal,
    private imageCacheService: ImageCacheService,
  ) {
    this.memoizedUpdateAvatarInfo = memoizee((res: any) => this.updateAvatarInfo(res), {
      primitive: true,
      maxAge: 5000,
      normalizer: (args) => JSON.stringify(args[0]),
    });

    this.memoizedSetInternalUserInfo = memoizee((res: any) => this.setInternalUserInfo(res), {
      primitive: true,
      maxAge: 5000,
      normalizer: (args) => JSON.stringify(args[0]),
    });

    this.memoizedUpdateAssistantStatus = memoizee(
      (tenantUsers: any[]) => this.updateAssistantStatus(tenantUsers),
      {
        primitive: true,
        maxAge: 5000,
        normalizer: (args) => JSON.stringify(args[0]),
      },
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userId'] && !changes['userId'].firstChange) {
      this.initAvatar();
    }
  }

  ngOnDestroy() {
    console.log(`${this.logPrefix} Component destroying for userId:`, this.userId);
    this.cleanup();
    this.destroy$.next();
    this.destroy$.complete();
    clearTimeout(AvatarComponent.batchTimeout);
    this.memoizedUpdateAvatarInfo.clear();
    this.memoizedSetInternalUserInfo.clear();
    this.memoizedUpdateAssistantStatus.clear();
  }

  initAvatar() {
    if (!this.userId) {
      console.warn(`${this.logPrefix} initAvatar called without userId`);
      return;
    }

    console.log(`${this.logPrefix} Initializing avatar for userId:`, this.userId);
    this.isLoading = true;
    this.cleanup();
    this.queueUserInfoFetch(this.userId);

    // Combine streams to reduce subscriptions
    const userInfo$ = this.store.select(UsersState.getUsersInfo);
    const tenantUsers$ = this.store.select(UsersState.getTenantUsers);
    const statusUpdates$ = this.store.select(UsersState.getUsersStatusesUpdates);

    this.usersInfo$ = combineLatest([userInfo$, tenantUsers$, statusUpdates$])
      .pipe(untilDestroyed(this), distinctUntilChanged(), debounceTime(50))
      .subscribe(([userInfo, tenantUsers, statusUpdates]) => {
        console.log(`${this.logPrefix} Received updates:`, {
          hasUserInfo: !!userInfo,
          tenantUsersCount: tenantUsers?.length,
          hasStatusUpdates: !!statusUpdates,
        });
        this.memoizedUpdateAvatarInfo(userInfo);
        this.memoizedUpdateAssistantStatus(tenantUsers);
        this.updateUserStatus(statusUpdates);
      });
  }

  private updateAvatarInfo(res: any) {
    if (this.userId && (this.isExternal || this.userId.includes('@'))) {
      this.setExternalUserInfo();
    } else {
      this.setInternalUserInfo(res);
    }
  }

  private setExternalUserInfo() {
    this.avatarUrl = null;
    this.status = null;
    this.userName = this.userId;
    this.prefix = this.userId.charAt(0);
    this.isLoading = false;
    this.markForCheck();
  }

  private setInternalUserInfo(res: any) {
    const userInfo = res[this.userId];
    if (!userInfo) {
      console.warn(`${this.logPrefix} No user info found for userId:`, this.userId);
      return;
    }

    const prefix: string = userInfo?.userName || this.userId;
    this.userName = userInfo?.userName;
    this.prefix = prefix?.charAt(0)?.toUpperCase();
    this.status = userInfo?.status;

    if (userInfo?.avatarUrl) {
      this.imageCacheService
        .preloadImage(userInfo.avatarUrl)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (url) => {
            this.avatarUrl = url;
            this.isLoading = false;
            this.markForCheck();
          },
          error: () => {
            this.avatarUrl = null;
            this.isLoading = false;
            this.markForCheck();
          },
        });
    } else {
      this.avatarUrl = null;
      this.isLoading = false;
      this.markForCheck();
    }
  }

  private updateAssistantStatus(tenantUsers: any[]) {
    if (!tenantUsers?.length) return;

    const isUserAssistant = tenantUsers.some(
      (user) => user._id === this.userId && user.isAssistant,
    );

    if (isUserAssistant) {
      this.isAssistant = true;
      this.markForCheck();
    }
  }

  private updateUserStatus(statusUpdates: any) {
    if (!statusUpdates || !this.userId) return;

    const newStatus = statusUpdates[this.userId];
    if (newStatus && this.status !== newStatus) {
      this.status = newStatus;
      this.markForCheck();
    }
  }

  private cleanup() {
    this.usersInfo$?.unsubscribe();
    this.usersStatusesUpdates$?.unsubscribe();
  }

  private markForCheck() {
    this.cdr.markForCheck();
  }

  private queueUserInfoFetch(userId: string) {
    console.log(`${this.logPrefix} Queueing user info fetch:`, userId);
    AvatarComponent.userIdsToFetch.add(userId);

    clearTimeout(AvatarComponent.batchTimeout);
    AvatarComponent.batchTimeout = setTimeout(() => {
      const userIds = Array.from(AvatarComponent.userIdsToFetch);
      console.log(`${this.logPrefix} Dispatching bulk user info fetch:`, {
        userIdsCount: userIds.length,
        userIds,
      });
      if (userIds.length) {
        this.store.dispatch(new QueueGetBulkUsersInfo(userIds));
        AvatarComponent.userIdsToFetch.clear();
      }
    }, 100);
  }

  openUserProfile() {
    if (!this.notShowProfile) {
      const userId = this.store.selectSnapshot(AuthState.getUser)._id;
      const modalRef = this.modalService.open(UserViewComponent, {
        size: 'lg',
      });
      modalRef.componentInstance._id = this.userId;
      modalRef.componentInstance.isMyself = userId === this.userId;
    }
  }

  trackById(index: number, item: any): string {
    return item._id;
  }
}
