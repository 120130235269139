import memoizee from 'memoizee';

export const memoizedImageLoader = memoizee(
  (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(url);
      img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
      img.src = url;
    });
  },
  {
    primitive: true,
    maxAge: 300000, // Cache for 5 minutes
    promise: true,
    max: 500, // Maximum number of cached items
  },
);
