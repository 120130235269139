/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CalendarEventsChecksGetListResDto } from '../models/calendar-events-checks-get-list-res-dto';
import { CalendarEventsChecksUpdateReqDto } from '../models/calendar-events-checks-update-req-dto';
import { CalendarEventsCreateReqDto } from '../models/calendar-events-create-req-dto';
import { CalendarEventsDbDto } from '../models/calendar-events-db-dto';
import { CalendarEventsExceptionsCreateReqDto } from '../models/calendar-events-exceptions-create-req-dto';
import { CalendarEventsGetListResDto } from '../models/calendar-events-get-list-res-dto';
import { CalendarEventsMembersDbDto } from '../models/calendar-events-members-db-dto';
import { CalendarEventsMembersUpdateStatusReqDto } from '../models/calendar-events-members-update-status-req-dto';
import { CalendarEventsStopRemindDto } from '../models/calendar-events-stop-remind-dto';
import { CalendarEventsUpdateReqDto } from '../models/calendar-events-update-req-dto';
import { CalendarTicketsGetListResDto } from '../models/calendar-tickets-get-list-res-dto';
import { CommonSuccessResDto } from '../models/common-success-res-dto';
import { CalendarEventsGetUserEventsResDto } from '../models/calendar-events-get-user-events-res-dto';
import { Nullish } from '../../shared/utils/types';

@Injectable({
  providedIn: 'root',
})
export class CalendarEventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation calendarEventGetList
   */
  static readonly CalendarEventGetListPath = '/calendar-events';

  /**
   * get calendar-events list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarEventGetList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object?: string;
    objectId?: string;
    _id?: string;
    from?: string;
    to?: string;
  }): Observable<StrictHttpResponse<Array<CalendarEventsGetListResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarEventsService.CalendarEventGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
      rb.query('_id', params['_id'], {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CalendarEventsGetListResDto>>;
        }),
      );
  }

  /**
   * get calendar-events list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarEventGetList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object?: string;
    objectId?: string;
    _id?: string;
    from?: string;
    to?: string;
  }): Observable<Array<CalendarEventsGetListResDto>> {
    return this.calendarEventGetList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CalendarEventsGetListResDto>>) => r.body as Array<CalendarEventsGetListResDto>),
    );
  }

  /**
   * Path part for operation calendarEventCreate
   */
  static readonly CalendarEventCreatePath = '/calendar-events';

  /**
   * create calendar-event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CalendarEventsCreateReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarEventsService.CalendarEventCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * create calendar-event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CalendarEventsCreateReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.calendarEventCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation calendarTicketsGetList
   */
  static readonly CalendarTicketsGetListPath = '/calendar-events/tickets';

  /**
   * get calendar-tickets list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarTicketsGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarTicketsGetList$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object?: string;
    objectId?: string;
    from: string;
    to: string;
  }): Observable<StrictHttpResponse<Array<CalendarTicketsGetListResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarEventsService.CalendarTicketsGetListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('object', params.object, {});
      rb.query('objectId', params.objectId, {});
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CalendarTicketsGetListResDto>>;
        }),
      );
  }

  /**
   * get calendar-tickets list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarTicketsGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarTicketsGetList(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    object?: string;
    objectId?: string;
    from: string;
    to: string;
  }): Observable<Array<CalendarTicketsGetListResDto>> {
    return this.calendarTicketsGetList$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<CalendarTicketsGetListResDto>>) => r.body as Array<CalendarTicketsGetListResDto>,
      ),
    );
  }

  /**
   * Path part for operation calendarEventGetChecksList
   */
  static readonly CalendarEventGetChecksListPath = '/calendar-events/get-checks';

  /**
   * get calendar-events checks list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventGetChecksList()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarEventGetChecksList$Response(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<StrictHttpResponse<Array<CalendarEventsChecksGetListResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarEventsService.CalendarEventGetChecksListPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CalendarEventsChecksGetListResDto>>;
        }),
      );
  }

  /**
   * get calendar-events checks list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventGetChecksList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarEventGetChecksList(params?: {
    /**
     * Localization
     */
    'x-lang'?: string;
  }): Observable<Array<CalendarEventsChecksGetListResDto>> {
    return this.calendarEventGetChecksList$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<CalendarEventsChecksGetListResDto>>) =>
          r.body as Array<CalendarEventsChecksGetListResDto>,
      ),
    );
  }

  /**
   * Path part for operation calendarEventUpdateChecks
   */
  static readonly CalendarEventUpdateChecksPath = '/calendar-events/update-checks';

  /**
   * update calendar-events checks
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventUpdateChecks()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventUpdateChecks$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CalendarEventsChecksUpdateReqDto;
  }): Observable<StrictHttpResponse<Array<CalendarEventsChecksGetListResDto>>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarEventsService.CalendarEventUpdateChecksPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<CalendarEventsChecksGetListResDto>>;
        }),
      );
  }

  /**
   * update calendar-events checks
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventUpdateChecks$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventUpdateChecks(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CalendarEventsChecksUpdateReqDto;
  }): Observable<Array<CalendarEventsChecksGetListResDto>> {
    return this.calendarEventUpdateChecks$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<CalendarEventsChecksGetListResDto>>) =>
          r.body as Array<CalendarEventsChecksGetListResDto>,
      ),
    );
  }

  /**
   * Path part for operation calendarEventGetEvent
   */
  static readonly CalendarEventGetEventPath = '/calendar-events/{id}';

  /**
   * get calendar-events list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventGetEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarEventGetEvent$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CalendarEventsGetListResDto>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarEventsService.CalendarEventGetEventPath, 'get');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CalendarEventsGetListResDto>;
        }),
      );
  }

  /**
   * get calendar-events list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventGetEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarEventGetEvent(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CalendarEventsGetListResDto> {
    return this.calendarEventGetEvent$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventsGetListResDto>) => r.body as CalendarEventsGetListResDto),
    );
  }

  /**
   * Path part for operation calendarEventDelete
   */
  static readonly CalendarEventDeletePath = '/calendar-events/{id}';

  /**
   * delete calendar-event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarEventDelete$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarEventsService.CalendarEventDeletePath, 'delete');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * delete calendar-event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calendarEventDelete(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
  }): Observable<CommonSuccessResDto> {
    return this.calendarEventDelete$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation calendarEventUpdate
   */
  static readonly CalendarEventUpdatePath = '/calendar-events/{id}';

  /**
   * update calendar-events
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventUpdate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: CalendarEventsUpdateReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarEventsService.CalendarEventUpdatePath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * update calendar-events
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventUpdate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: CalendarEventsUpdateReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.calendarEventUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation calendarEventStopReminder
   */
  static readonly CalendarEventStopReminderPath = '/calendar-events/stop-remind/{id}';

  /**
   * stop remind calendar-event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventStopReminder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventStopReminder$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: CalendarEventsStopRemindDto;
  }): Observable<StrictHttpResponse<CalendarEventsDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarEventsService.CalendarEventStopReminderPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CalendarEventsDbDto>;
        }),
      );
  }

  /**
   * stop remind calendar-event
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventStopReminder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventStopReminder(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: CalendarEventsStopRemindDto;
  }): Observable<CalendarEventsDbDto> {
    return this.calendarEventStopReminder$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventsDbDto>) => r.body as CalendarEventsDbDto),
    );
  }

  /**
   * Path part for operation calendarEventUpdateMemberStatus
   */
  static readonly CalendarEventUpdateMemberStatusPath = '/calendar-events/update-member-status/{id}';

  /**
   * update calendar-events member status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventUpdateMemberStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventUpdateMemberStatus$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: CalendarEventsMembersUpdateStatusReqDto;
  }): Observable<StrictHttpResponse<CalendarEventsMembersDbDto>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarEventsService.CalendarEventUpdateMemberStatusPath, 'patch');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CalendarEventsMembersDbDto>;
        }),
      );
  }

  /**
   * update calendar-events member status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventUpdateMemberStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventUpdateMemberStatus(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    id: string;
    body: CalendarEventsMembersUpdateStatusReqDto;
  }): Observable<CalendarEventsMembersDbDto> {
    return this.calendarEventUpdateMemberStatus$Response(params).pipe(
      map((r: StrictHttpResponse<CalendarEventsMembersDbDto>) => r.body as CalendarEventsMembersDbDto),
    );
  }

  /**
   * Path part for operation calendarEventsExceptionsCreate
   */
  static readonly CalendarEventsExceptionsCreatePath = '/calendar-events/create-exception-days';

  /**
   * create calendar-event exception days
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventsExceptionsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventsExceptionsCreate$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CalendarEventsExceptionsCreateReqDto;
  }): Observable<StrictHttpResponse<CommonSuccessResDto>> {
    const rb = new RequestBuilder(this.rootUrl, CalendarEventsService.CalendarEventsExceptionsCreatePath, 'post');
    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CommonSuccessResDto>;
        }),
      );
  }

  /**
   * create calendar-event exception days
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventsExceptionsCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventsExceptionsCreate(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    body: CalendarEventsExceptionsCreateReqDto;
  }): Observable<CommonSuccessResDto> {
    return this.calendarEventsExceptionsCreate$Response(params).pipe(
      map((r: StrictHttpResponse<CommonSuccessResDto>) => r.body as CommonSuccessResDto),
    );
  }

  /**
   * Path part for operation calendarEventsExceptionsCreate
   */
  static readonly CalendarEventsGetUserEventsPath = '/calendar-events/get-user-events';

  /**
   * create calendar-event exception days
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calendarEventsExceptionsCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventsGetUserEvents$Response(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    userIds: string[];
    day: string;
    excludeEventId?: Nullish<string | number>;
  }): Observable<StrictHttpResponse<CalendarEventsGetUserEventsResDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      `${CalendarEventsService.CalendarEventsGetUserEventsPath}/66c443a942a75b08201192a3`,
      'get',
    );

    if (params) {
      rb.header('x-lang', params['x-lang'], {});
      rb.query('userIds[]', params.userIds, {});
      rb.query('day', params.day, {});
      rb.query('excludeEventId', params.excludeEventId, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<CalendarEventsGetUserEventsResDto>;
        }),
      );
  }

  /**
   * create calendar-event exception days
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calendarEventsGetGuestEvents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  calendarEventsGetUserEvents(params: {
    /**
     * Localization
     */
    'x-lang'?: string;
    userIds: string[];
    day: string;
    excludeEventId?: Nullish<string | number>;
  }): Observable<CalendarEventsGetUserEventsResDto> {
    return this.calendarEventsGetUserEvents$Response(params).pipe(
      map(
        (r: StrictHttpResponse<CalendarEventsGetUserEventsResDto>) =>
          r.body as CalendarEventsGetUserEventsResDto,
      ),
    );
  }
}
