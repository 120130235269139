import swal from 'sweetalert2';

export interface ConfirmAlertOptions {
  subject?: string;
  text?: string;
  confirmButtonText?: string;
  showCancelButton?: boolean;
  cancelButtonText?: string;
  denyButtonText?: string;
  showDenyButton?: boolean;
  confirmButtonClass?: string;
  denyButtonClass?: string;
  platform?: string;
  width?: number;
}

/**
 * Show confirmation popup, return result
 * By default works as Confirm Delete popup
 * @param  {} subject
 * @param options
 * @returns Promise
 */
export function ConfirmAlert(
  subject = null,
  options: ConfirmAlertOptions = {},
): Promise<'isConfirmed' | 'isDenied'> {
  const title = 'Are you sure you want to delete this {subject}?';
  const text = 'You will not be able to revert this!';
  const confirmButtonText = 'Yes, delete it!';
  const cancelButtonText = 'Cancel';
  let confirmButtonClass = 'btn-danger';
  let denyButtonClass = 'btn-danger';
  let customClass: any;

  if (options?.showDenyButton) {
    confirmButtonClass = 'btn-solid';
  }

  if (options?.confirmButtonClass) {
    confirmButtonClass = options.confirmButtonClass;
  }

  if (options?.denyButtonClass) {
    denyButtonClass = options.denyButtonClass;
  }

  customClass = {
    cancelButton: `btn btn-subtle${options?.platform !== 'web' && options?.showDenyButton ? ' mb-1' : ''}`,
    denyButton: `btn ml-2 ${denyButtonClass}${options?.platform !== 'web' ? ' mb-1' : ''}`,
    confirmButton: `btn ml-2 ${confirmButtonClass}`,
  };

  if (options?.platform !== 'web') {
    customClass = { ...customClass, container: 'swal2-container-mobile' };
  }

  const swalOptions: any = {
    title: !options?.subject ? title.replace('{subject}', subject) : options.subject,
    // icon: 'warning',
    showCloseButton: true,
    showCancelButton: true,
    showDenyButton: options.showDenyButton,
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
    customClass,
    buttonsStyling: false,
    reverseButtons: true,
    padding: options?.platform === 'web' ? '20px' : '16px',
    width: options?.platform === 'web' ? 450 : 'calc(100% - 80px)',
  };

  if (options?.text && options?.text !== '') {
    swalOptions.text = options.text;
  } else if (options?.text === '') {
  } else {
    swalOptions.text = text;
  }

  if (options?.confirmButtonText) {
    swalOptions.confirmButtonText = options.confirmButtonText;
  }

  if (options?.showCancelButton === false) {
    swalOptions.showCancelButton = false;
  }

  if (options?.cancelButtonText) {
    swalOptions.cancelButtonText = options.cancelButtonText;
  }

  if (options?.denyButtonText) {
    swalOptions.denyButtonText = options.denyButtonText;
  }

  if (options?.width) {
    swalOptions.width = options.width;
  }

  return new Promise((resolve, reject) => {
    swal.fire(swalOptions).then(function (result) {
      if (result.isConfirmed || result.isDenied) {
        resolve(result.isConfirmed ? 'isConfirmed' : 'isDenied');
      } else if (result.dismiss === swal.DismissReason.cancel) {
        reject();
      }
    });
  });
}
